<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <div class="flip-card-left-container" >
    <input type="checkbox" id="card1" class="more" aria-hidden="true">
    <div class="sdk-btm__card content">
      <div class="sdk-btm__image bloody-mary__img ">
        <div class="title-content">

        </div>
      </div>
      <div class="sdk-btm__text bloody-mary">

        <div class="sdk-btm__text-ingredients"
        style="max-width: 600px; display: grid; align-content: center;
          justify-content: center; align-items: center; justify-items: center;">
          <h2 class="title_card" style="    ">
            Malibu is giving away
          </h2>
          <h2 class="title_card2">
            $100,000
          </h2>
          <h2 class="title_card3">
            to Pina Colada Lovers All Summer Long!
          </h2>
          <p class="text_card">
            Enter For Your Chance to Win $15!
          </p>

          <div class="container_button">
            <button class="btn_cta" @click="hrefLocation">
              Enter here
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      selectedState: null,
    };
  },
  methods: {
    hrefLocation() {
      const gAnalitycs = {
        value: 'Click_Sweeps Enter Button',
        event_action: 'Click_Sweeps Enter Button',
        event_category: 'Button Click',
        event_label: 'Sweeps Enter Button',
      };
      this.$gtag.event(gAnalitycs.value, gAnalitycs);

      window.open('https://www.maliburumdrinks.com/en-us/PinaColadaPromo/');
    },
  },
};
</script>

<style lang="scss"></style>
