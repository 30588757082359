<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <section id="cocktails_section">
    <div class="container_coktails">
      <h2>
        piña colada recipes
      </h2>
      <p>
        Because an unforgettable summer deserves unforgettable drinks!
      </p>

      <div class="carousel">
        <div class="carousel-inner"
             :style="{ transform: `translateX(-${slideIndex * slideWidth}px)` }">
          <div class="cocktails_malibu"
               v-for="(cocktail, index) in cocktails" :key="index">
            <div class="cocktail_malibu">
              <img :src="cocktail.image" alt="">
              <p class="cocktail_name">{{ cocktail.name }}</p>
              <a @click="hreflocation(cocktail)"
              target="_blank">Get Recipe <img :src="go" alt=""></a>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-controls" v-if="isMobileView">
        <button @click="prevSlide" :disabled="slideIndex === 0">
          <img :src="prev" alt="">
        </button>
        <button @click="nextSlide" :disabled="slideIndex === (cocktails.length - 1)">
          <img :src="next" alt="">
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { State } from 'country-state-city';
import adresses from '@/utils/addresses.json';

const VUE_APP_S3 = 'https://archeio2.nyc3.cdn.digitaloceanspaces.com/malibu/2023_pinia_colada/';

export default {
  components: {},
  data() {
    return {
      selectedState: null,
      markers: [],
      options: [],
      selected: null,
      cocktails: [
        {
          name: 'piña colada',
          image: `${VUE_APP_S3}cocktails/cocktail_pinacolada.png`,
          url: 'https://www.maliburumdrinks.com/en-us/drinks/malibu-pina-colada/',
          label: 'Recipe Pina Colada Button',
          action: 'Click_Recipe Pina Colada Button',
        },
        {
          name: 'piña colada slushy',
          image: `${VUE_APP_S3}cocktails/cocktail_slushy.png`,
          url: 'https://www.maliburumdrinks.com/en-us/drinks/malibu-pina-colada-slushy/',
          label: 'Recipe Pina Slushy Button',
          action: 'Click_Recipe Pina Slushy Button',
        },
        {
          name: 'coconut water piña colada',
          image: `${VUE_APP_S3}cocktails/cocktail_coconutwater.png`,
          url: 'https://www.maliburumdrinks.com/en-us/drinks/malibu-coconut-water-pina-colada/',
          label: 'Recipe Pina Coco Water Button',
          action: 'Click_Recipe Pina Coco Water Button',
        },
        {
          name: 'sparkling piña colada',
          image: `${VUE_APP_S3}cocktails/cocktail_sparkling.png`,
          url: 'https://www.maliburumdrinks.com/en-us/drinks/malibu-sparkling-pina-colada/',
          label: 'Recipe Pina Sparkling Button',
          action: 'Click_Recipe Pina Sparkling Button',
        },
      ],
      slideIndex: 0,
      slideWidth: 0,
      isMobileView: false,
      go: `${VUE_APP_S3}icons/go.png`,
      next: `${VUE_APP_S3}icons/next.png`,
      prev: `${VUE_APP_S3}icons/prev.png`,
    };
  },
  computed: {},
  methods: {
    hreflocation(cocktail) {
      const gAnalitycs = {
        value: cocktail.action,
        event_action: cocktail.action,
        event_category: 'Button Click',
        event_label: cocktail.label,
      };
      this.$gtag.event(gAnalitycs.value, gAnalitycs);
      window.open(cocktail.url);
    },
    getSlideWidth() {
      const slide = this.$el.querySelector('.cocktails_malibu');
      return slide.offsetWidth + parseInt(getComputedStyle(slide).marginRight, 2);
    },
    initCarousel() {
      window.addEventListener('resize', () => {
        this.slideWidth = this.getSlideWidth();
        this.slideIndex = 0;
        this.$nextTick(() => {
          this.slideTo(0);
        });
      });
    },
    checkMobileView() {
      this.isMobileView = window.innerWidth <= 768;
      if (this.isMobileView) {
        this.slideIndex = 0;
      }
    },
    slideTo(index) {
      this.slideIndex = index;
    },
    prevSlide() {
      if (this.slideIndex > 0) {
        this.slideIndex -= 1;
      }
    },
    nextSlide() {
      if (this.slideIndex < this.cocktails.length - 1) {
        this.slideIndex += 1;
      }
    },
  },
  mounted() {
    this.options = State.getStatesOfCountry('US');
    this.markers = adresses.filter((ad) => ad.active === 1);
    this.slideWidth = this.getSlideWidth();
    this.initCarousel();
    this.checkMobileView();
    window.addEventListener('resize', this.checkMobileView);
  },
  unmounted() {
    window.removeEventListener('resize', this.checkMobileView);
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">
.container_coktails {
  background-image: url("https://archeio2.nyc3.cdn.digitaloceanspaces.com/malibu/2023_pinia_colada/banner_cocktails.jpg");
  width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding-top: 2em;
    padding-bottom: 2em;

  h2 {
    font-family: 'CubanoItalic';
    font-style: normal;
    font-weight: 400;
    font-size: 58px;
    line-height: 70px;
    /* identical to box height */

    text-align: center;

    color: #EB5573;
    text-transform: uppercase;
    @media screen and (max-width: 600px) {

      font-size: 32px;
    }
  }

  p {
    font-family: 'Geoxe3';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 140%;
    text-align: center;
    color: #23161B;

    @media screen and (max-width: 600px) {
      font-size: 18px;
      font-size: 18px;
      max-width: 335px;
      text-align: center;
      display: grid;
      justify-items: center;
      align-items: center;
      justify-content: center;
      align-content: center;
      margin: 0 auto;
    }
  }
}

.carousel {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-bottom: 20px;
}

.carousel-inner {
  display: flex;
  transition: transform 0.3s ease-in-out;
  @media (min-width: 768px) {
    justify-content: center;
  }
}

.cocktails_malibu {
  flex: 0 0 auto;
  margin-right: 45px;
  width: auto;
  @media (max-width: 768px) {
    margin-right: 0;
    width: 100% !important;
  }
}

.cocktail_malibu {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media (min-width: 768px) {
    justify-content: center;
    align-content: stretch;
  }
  img {
    height: 437px;
  }
  p {
    font-family: 'CubanoItalic';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 31px;
    text-align: center;
  min-height: 60px!important;

    color: #EB5573;
    text-transform: uppercase;
    max-width: 225px;
  }
  a {
    font-family: 'Geoxe3';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #23161B;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 7px;
    img {
      width: 13px;
      height: auto;
    }
  }
}
.carousel-controls {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  top: 60%;
  padding: 24px;
}

</style>
