<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <section id="cocktails_section">
    <div class="container_enjoy">
      <div class="container_description_enjoy">
        <h3>
          EMBRACE THE SUMMER MINDSET WITH THIS FEEL-GOOD SONG —
        </h3>
        <h2>
          AND ENJOY A MALIBU PIÑA COLADA WHILE YOU'RE AT IT!
        </h2>
        <p>
          This modern remix with Jax is just what your good vibes playlist needs!

        </p>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  components: {},
  data() {
    return {
    };
  },
  computed: {},
  methods: {
    hreflocation(cocktail) {
      const gAnalitycs = {
        value: 'Click_Enjoy Button',
        event_action: 'Click_Enjoy Button',
        event_category: 'Button Click',
        event_label: cocktail.label,
      };
      this.$gtag.event(gAnalitycs.value, gAnalitycs);
      window.open(cocktail.url);
    },
  },
  mounted() {
  },
  unmounted() {
  },
};
</script>
<style lang="scss">
.container_enjoy {
  background-image: url("https://plant.hellosandia.com/img/test/bannerEnjoy.jpg");
  width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding-top: 2em;
    padding-bottom: 2em;
    height: 900px;
    text-align: initial;
    @media screen and (max-width: 600px) {
      background-image: url("https://plant.hellosandia.com/img/test/bannerEnjoyMobile.jpg");

    }
    h3{
      font-family: 'CubanoItalic';
      font-style: normal;
      font-weight: 400;
      font-size: 45.9829px;
      line-height: 55px;

      color: #FFFFFF;
      text-align: initial;

      @media screen and (max-width: 600px) {
        font-size: 22.2152px;
        line-height: 27px;
        text-align: center;

      }
    }

    h2{
      font-family: 'CubanoItalic';
      font-style: normal;
      font-weight: 400;
      font-size: 79.5965px;
      line-height: 96px;

      color: #07C0B4;
      text-align: initial;

      @media screen and (max-width: 600px) {
        font-size: 38.4545px;
        line-height: 46px;
        text-align: center;

      }
    }
    p{
      font-family: 'geoxe3';
      font-style: normal;
      font-weight: 500;
      font-size: 33.3792px;
      line-height: 140%;
      color: #FFFFFF;
      text-align: initial;

     @media screen and (max-width: 600px) {
        font-size: 16.1261px;
        line-height: 140%;
        text-align: center;

      }
    }
    .container_description_enjoy{
      position: absolute;
      top: 0;
      z-index: 9999;
      display: grid;
      align-content: center;
      justify-content: start;
      align-items: start;
      justify-items: start;
      text-align: initial;
      top: 50%;
      bottom: 50%;
      left: 10%;
      max-width: 550px;

      @media screen and (max-width: 600px) {
        position: relative;
        top: 10px;
        max-width: 265px;
        margin: 0 auto;
        bottom: auto;
    left: auto;
    top: auto;
      }

    }
  }
</style>
