<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <section id="map">
    <div class="column column-left">
      <div class="choose-state">
        <h2 class="title_filters">WE'RE IN YOUR NEIGHBORHOOD</h2>
        <div class="select-container">
          <h1>STATE</h1>
          <multiselect  v-model="selected" :options="options" label="name"
                        track-by="isoCode" placeholder="Select State">
          </multiselect>
          <div class="button-container">
            <Button @click="handleSearchButton" type="button" class="search_state">
              Search
            </Button>
          </div>
        </div>

        <div class="container_cocktail_message desktop" v-if="!selectedMarker && !selectedState">
          <img :src="iconCocktail" alt="" />
          <p>
            Celebrate summer! Choose your state to see which bars and restaurants
            are pouring your dream Malibu Piña Colada.
          </p>
        </div>

        <div class="container_cocktail_message desktop"
        v-else-if="!selectedMarker && selectedState">
          <img :src="iconState" alt="" />
          <p>
            Choose a restaurant or bar from the list to see the Malibu Piña Colada
            recipe that they are offering this summer!
          </p>
        </div>

        <div class="container_cocktail_state desktop" v-else>
          <div class="state_cocktail_image">
            <img :src="cocktailPinaCola" alt="" class="img_cocktails">
          </div>
          <div class="state_cocktail_description">
            <h2>{{selectedMarker.cocktail ?? ''}}</h2>
            <!--
            <p>
              One of the world’s most popular summer cocktails,
              the Piña Colada tastes refreshing and sweet.
              It´s super easy to make and perfect to enjoy with friends.
              It’s no surprise why it’s a favorite in beach bars everywhere!
            </p>-->
          </div>
        </div>

      </div>
    </div>
    <div class="container_cocktail_message mobile" v-if="!selectedMarker && !selectedState">
      <img :src="iconCocktail" alt="" style=" width: 40px;"/>
      <p>
        Celebrate summer! Choose your state to see which bars and restaurants
        are pouring your dream Malibu Piña Colada.
      </p>
    </div>

    <div class="column column-right">
      <map-component :markers="markers"></map-component>
    </div>

    <div class="container_cocktail_message mobile"
      v-if="!selectedMarker && selectedState">
      <img :src="iconState" alt=""  style=" width: 40px;" />
      <p>
        Choose a restaurant or bar from the list to see the Malibu Piña Colada
        recipe that they are offering this summer!
      </p>
    </div>

    <div class="container_cocktail_state mobile" v-if="selectedMarker && selectedState">
      <div class="state_cocktail_description">
        <h2>{{selectedMarker.cocktail ?? ''}}</h2>
        <!--
        <p>
          One of the world’s most popular summer cocktails,
          the Piña Colada tastes refreshing and sweet.
          It´s super easy to make and perfect to enjoy with friends.
          It’s no surprise why it’s a favorite in beach bars everywhere!
        </p>
        -->
      </div>
    </div>

  </section>
</template>

<script>
import { State } from 'country-state-city';
import Multiselect from 'vue-multiselect';
import adresses from '@/utils/addresses.json';
import emitter from '@/utils/emitter';
import { mapState } from 'vuex';
import MapComponent from './MapComponent.ce.vue';

const VUE_APP_S3 = 'https://archeio2.nyc3.cdn.digitaloceanspaces.com/malibu/2023_pinia_colada/';

export default {
  components: {
    MapComponent,
    Multiselect,
  },
  data() {
    return {
      iconCocktail: `${VUE_APP_S3}icons/icon-cocktail.svg`,
      iconState: `${VUE_APP_S3}icons/icon-state.svg`,
      cocktailPinaCola: `${VUE_APP_S3}cocktails/cocktail_pinacolada.png`,
      selectedState: null,
      markers: [],
      options: [],
      selected: null,
    };
  },
  watch: {
    userIPinfo(newValue) {
      if (newValue?.state_name) {
        const stateName = newValue?.state_name ?? null;
        const states = State.getStatesOfCountry('US');
        const selected = states.find((stateObject) => stateName.toLowerCase().trim().replaceAll(' ', '')
          === stateObject.name.toLowerCase().trim().replaceAll(' ', '')) ?? null;
        this.selected = selected;
        return;
      }
      this.selected = null;
    },
    selected() {
      const gAnalitycs = {
        value: 'Select_Dropdown_State',
        event_action: 'Select_Dropdown_State',
        event_category: 'Dropdown Select',
        event_label: 'State',
      };
      this.$gtag.event(gAnalitycs.value, gAnalitycs);
    },
  },
  methods: {
    handleSearchButton() {
      const { selected } = this;
      this.selectedState = selected;

      const gAnalitycs = {
        value: 'Click_Rest Search Button',
        event_action: 'Click_Rest Search Button',
        event_category: 'Button Click',
        event_label: 'Rest Search Button',
      };
      this.$gtag.event(gAnalitycs.value, gAnalitycs);

      // console.log(selected);
      // debugger;
      emitter.emit('state-selected', selected);
    },
    selectStateByIP() {
      if (this.userIPinfo?.state_name) {
        const stateName = this.userIPinfo?.state_name ?? null;
        const states = State.getStatesOfCountry('US');
        const selected = states.find((stateObject) => stateName.toLowerCase().trim().replaceAll(' ', '')
          === stateObject.name.toLowerCase().trim().replaceAll(' ', '')) ?? null;
        // debugger;
        this.selected = selected;
      }
    },
  },
  computed: {
    ...mapState(['userIPinfo', 'selectedMarker']),
    // computedUserCoords() {
    //   const { userCoords } = this;
    //   return userCoords;
    // },
  },
  mounted() {
    // https://developers.google.com/maps/documentation/javascript/examples/full/images/info-i_maps.png
    // console.log(State.getStatesOfCountry('US'));
    this.options = State.getStatesOfCountry('US');
    // TODO: llamar api de obtener info geografica por ip.
    // this.selectedState = result;
    this.markers = adresses.filter((ad) => ad.active === 1);
    // this.selectStateByIP();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">
section#map {
  min-height: 100vh;
  min-height: 100dvh;
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @media screen and (min-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  .desktop {
    @media screen and (max-width: 600px) {
      display: none !important;
    }
  }

  .mobile {
    @media screen and (min-width: 600px) {
      display: none !important;
    }
  }

  .select {
    padding: 5px;
  }

  .choose-state {
    display: grid;
    align-content: center;
    padding: 2em;
    justify-content: center;
    height: 100%;

    @media screen and (max-width: 800px) {
      padding-top: 0;
      padding: 2em;
    }

    .title_filters {
      font-size: 43px;
      line-height: 120%;

      @media screen and (min-width: 600px) {
        font-size: 67.9355px;
        line-height: 77px;
      }
    }

    .select-container {
      display: flex;
      flex-flow: column;
      width: 100%;
      gap: 10px;
      margin-top: 25px;

      @media screen and (min-width: 600px) {
        flex-flow: row wrap;
      }

      h1 {
        display: block;
        width: 100%;
        margin: 0;
        text-align: left;
        font-family: "CubanoItalic";
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 120%;
        color: #EB5573;
        @media screen and (max-width: 600px) {
          font-size: 23px;
        }
      }

      .multiselect__placeholder {
        font-family: geoxe3;
      }

      .multiselect__single {
        font-family: geoxe3;
        background-color: transparent;
      }

      .multiselect__element,
      .multiselect__option {
        font-family: geoxe3;

        span {
          font-family: geoxe3;
        }
      }

      .multiselect {
        display: flex;
        flex: 1;
        align-items: center;

        .multiselect__select {
          display: flex;
          // align-items: center;
        }

        .multiselect__tags {
          width: 100%;
        }
      }

      button.search_state {
        display: block;
        margin: 0 !important;
        min-width: 100%;
        padding: 5px 15px;
      }

      .button-container {
        display: flex;
        // background-color: blue;
      }

      .search_state {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .container_cocktail_state {
    &.mobile {
      display: grid;
      padding: 2em;
      grid-template-columns: 1fr;
    }
  }

  .container_cocktail_message {
    display: grid;
    padding: 2em;
    grid-template-columns: 1fr;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
    row-gap: 10px;
    padding-top: 0px;
    // @media screen and (max-width: 600px) {
    //   padding-top: 0;
    // }
    @media screen and (min-width: 600px) {
      padding-top: 80px;
    }

    p{
      font-family: 'geoxe3';
      font-style: normal;
      font-size: 24px;
      line-height: 145%;
      text-align: center;
      color: #23161B;
      @media screen and (max-width: 600px) {
        font-size: 18px;
        max-width: 310px;
      }
    }
  }

}

.multiselect__option--highlight {
  background: #07c0b4 !important;
}

.multiselect__spinner::before,
.multiselect__spinner::after {
  border-color: #07c0b4 transparent transparent;
}

.multiselect__tag {
  background: #07c0b4 !important;
}

.multiselect__option--highlight {
  background: #07c0b4 !important;
}

.multiselect__option--highlight::after {
  background: #07c0b4 !important;
}

section#map .choose-state .select-container .multiselect .multiselect__tags {
    width: 100%;
    background: #F5F5F5;
}

section#map .choose-state .select-container .multiselect__element span,
 section#map .choose-state .select-container .multiselect__option span {
  background: #F5F5F5;

}

element.style {
    width: 0px;
    position: absolute;
    padding: 0px;
}

.sdk-main input {
    line-height: normal;
    background: #F5F5F5;
}

section#map .choose-state .select-container .multiselect__element span,
section#map .choose-state .select-container .multiselect__option span {
  color: BLACK;
}

.multiselect__option--highlight.multiselect__option{
  span{
    background: #07c0b4 !important;
    color:white !important;
  }
}
</style>
