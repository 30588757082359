<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <div class="flip-card-left-container" >
    <input type="checkbox" id="card1" class="more" aria-hidden="true">
    <div class="sdk-btm__card content">

      <div class="sdk-btm__text bloody-mary">

        <div class="sdk-btm__text-ingredients"
        style="    max-width: 690px; display: grid; align-content: center;
          justify-content: center; align-items: center; justify-items: center; padding:20px 40px;">

          <h2 class="title_card4">
            IF YOU LIKE MALIBU PIÑA COLADAS: COCKTAIL KIT
          </h2>
          <p class="text_card">
            Malibu is here to make sure your Piña Colada songs and style are best
            paired with delicious, easy to make sips. Don't miss the go-to brand
            for Piña Coladas' limited-time only Malibu cocktail kit perfect to enjoy with your crew!
          </p>

          <div class="container_button">
            <button class="btn_cta" @click="hrefLocation">
              Order Now
            </button>
          </div>

        </div>
      </div>
      <div class="sdk-btm__image vodkarita__img ">
        <div class="title-content">

        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      selectedState: null,
    };
  },
  methods: {
    hrefLocation() {
      const gAnalitycs = {
        value: 'Click_Kit Order Button',
        event_action: 'Click_Kit Order Button',
        event_category: 'Button Click',
        event_label: 'Click_Kit Order Button',
      };
      this.$gtag.event(gAnalitycs.value, gAnalitycs);
      window.open('https://sourcedcraftcocktails.com/products/if-you-like-malibu-pina-coladas');
    },
  },
};
</script>

<style lang="scss"></style>
