/* eslint-disable no-underscore-dangle */
import {
  defineCustomElement as VueDefineCustomElement, h, createApp, getCurrentInstance,
} from 'vue';

import VueGoogleMaps from '@fawmi/vue-google-maps';
// eslint-disable-next-line
import VueGtag from 'vue-gtag';

const getStylesRecursively = (component) => {
  // debugger;
  const customElementStyles = [];

  if (component.styles) {
    customElementStyles.push(...component.styles);
  }

  const childComponents = component.components;
  if (childComponents) {
    Object.keys(childComponents).forEach((name) => {
      const styles = getStylesRecursively(childComponents[name]);
      customElementStyles.push(...styles);
    });
  }

  return customElementStyles;
};

const defineCustomElement = (component, { plugins = [] } = {}) => VueDefineCustomElement({
  render: () => h(component),
  styles: getStylesRecursively(component),
  setup() {
    const app = createApp();

    // install plugins
    plugins.forEach(app.use);
    app.use(VueGoogleMaps, {
      load: {
        key: process?.env?.VUE_APP_MAPS ?? '',
      },
    });
    app.use(VueGtag, {
      includes: [
        { id: 'G-NQ7RQ4VGFL' },
        { id: 'G-M1MVME2FZC' },
      ],
      config: { id: 'G-JT42LQBJZW' },
    });

    const inst = getCurrentInstance();
    Object.assign(inst.appContext, app._context);
    Object.assign(inst.provides, app._context.provides);
  },
});

export default defineCustomElement;
