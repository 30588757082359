<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <div class="" >
    <input type="checkbox" id="card1" class="more" aria-hidden="true">
    <div class="sdk-btm__card content">
      <div class="sdk-btm__image espresso-martini__img ">
        <div class="title-content">

        </div>
      </div>
      <div class="sdk-btm__text bloody-mary ">

        <div class="sdk-btm__text-ingredients" style="max-width: 750px; padding: 0 40px;">
          <h2 class="title_card4">
            If You Like Piña Coladas, Try a DJ Cassidy Colada
            <br class="d-none-desktop-br"/> & New Remix
          </h2>
          <p class="kenny_description">
            DJ Cassidy, who just released If You Like Piña Coladas
            with Shaggy and Rayvon, shares another remix: his favorite
            Malibu Piña Colada recipe – a key lime pie party in paradise!
            Malibu says Do Whatever Tastes Good and remix your
            style – enjoy the DJ Cassidy Colada recipe and new song.
          </p>
          <div class="container_button">

            <button class="btn_cta2" @click="hrefLocation">Listen Now</button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      selectedState: null,
    };
  },
  methods: {
    hrefLocation() {
      const gAnalitycs = {
        value: 'Click_Lime_Pina_Colada_Button',
        event_action: 'Click_Lime_Pina_Colada_Button',
        event_category: 'Button Click',
        event_label: 'Lime Pina Colada Button',
      };
      this.$gtag.event(gAnalitycs.value, gAnalitycs);
      window.open('https://www.instagram.com/p/CufAUz1gEuW/');
    },
  },
};
</script>

<style lang="scss">

.flip-card-left-container {

  .sdk-btm__card.content {
      @media screen and (max-width: 800px) {
        & {
          display: flex !important;
          flex-direction: column-reverse !important;
        }
      }
  }
}
.kenny_description {
  font-family: 'geoxe3';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  /* or 34px */

  text-align: center;

  color: #23161B;

  @media screen and (max-width: 600px) {
    font-family: 'geoxe3' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 140% !important;
    /* or 25px */

    text-align: center !important;

    color: #23161B !important;
    max-width: 300px !important;
    margin: 0 auto !important;
  }
}
.d-none-mobile-br{
      display: none;
      @media screen and (max-width: 600px) {
        display: block;
      }
  }
</style>
